export const TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const log = (message, object = null, type = TYPE.INFO) => {
  if (
    process.env.REACT_APP_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'staging'
  ) {
    let style = [];
    switch (type) {
      case TYPE.SUCCESS:
        style = ['color: #42ff09'];
        break;
      case TYPE.INFO:
        style = ['color: #00e4ff'];
        break;
      case TYPE.WARNING:
        style = ['color: #ffcb00'];
        break;
      case TYPE.ERROR:
        style = ['color: #ff0000'];
        break;
      default:
        style = ['color: #00e4ff'];
        return;
    }
    // eslint-disable-next-line no-console
    console.log(`%c${message}`, style.join(';'));
    if (object) {
      // eslint-disable-next-line no-console
      console.log(object);
    }
  }
};
